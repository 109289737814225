import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { getDevice } from '~/libs/es/device'

const img = {
    button: '/images/line.at-tutor4dev-button.png',
    qrcode: '/images/line.at-tutor4dev-qrcode.png'
}

export default function ({ data, pageContext }) {
    const { markdownRemark: { html } } = data

    useEffect(() => {
        if (getDevice() === 'desktop') {
            document.querySelectorAll(`img[src='${img.button}']`)
                .forEach(each => {
                    each.src = img.qrcode
                })
        }
    }, [])

    return <div className="content is-page">
        <Helmet>
            <title>{pageContext.helmetTitle}</title>
        </Helmet>

        <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </div>
}

export const query = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            tableOfContents
            html
            frontmatter {
                title
            }
        }
    }
`